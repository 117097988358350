<template>
  <div>
    <Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="banner">
        <img src="../../../assets/images/offline-experience-banner.jpg" alt="">
      </div>

      <div class="crumbs">
        <div class="page">
          首页 / 线下体验
        </div>
      </div>
      <div class="offline-wrap">
        <div class="page">
          <div class="title">久仰不如亲鉴</div>
          <div class="desc">欢迎参加小院生活居住体验</div>
          <div class="map-wrap">
            <div class="left">
              <Map class="mapbox" :storeList="activeStore"></Map>
            </div>
            <div class="right">
              <div class="address-list">
                <div class="address-item" v-for="(item,index) in storeList" :key="index" :class="item.active?'active':''" @click="addressClick(item.id)">
                  <div class="name">
                    <img src="../../../assets/images/fengche.svg" alt="">
                    <span>{{item.name}}</span>
                  </div>
                  <div class="address">
                    <span class="iconfont icon-locationfill"></span>
                    <span>{{item.address}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactUs></ContactUs>

    </div>
    <Footer2022></Footer2022>
  </div>
</template>

<script>
import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
import ContactUs from '@/components/contact-us.vue'
import Map from '@/components/map'
import utils from '@/common/utils'

export default {
  data () {
    return {
      storeList: [
        {
          id:2,
          name: "小院生活2号体验中心",
          address: "地址：泾渭五路水一方观河",
          lnglat: [109.016351,34.441869],
          active: true,
        },
        {
          id:3,
          name: "小院生活3号体验中心",
          address: "地址：泾渭八路水半湾小院",
          lnglat: [109.029439,34.444592],
          active: false,
        },
        {
          id:4,
          name: "小院生活4号体验中心",
          address: "地址：沣西新城交大创新港",
          lnglat: [108.667088,34.257408],
          active: false,
        },
      ],
    }
  },
  components: {
   Header2022,
   Footer2022,
    ContactUs,
    Map
  },
  computed: {
    activeStore:function() {
      let storeList = this.storeList;
      let activeStore =storeList.filter( item => {
        return item.active == true
      })
      return activeStore
    }
  },
  methods: {
    addressClick:utils.debounce(function(id){
      let that = this;
      let storeList = that.storeList

      for (const item of storeList) {
        if(id == item.id){
          item.active = true
        }else {
          item.active = false
        }
      }

      that.storeList = storeList
    },200,true),
  },
}
</script>

<style lang="scss" scoped>
.content{
  padding-top: 64px;
  .banner{
    width: 100%;
    img{
      width: 100%;
    }
  }
  .crumbs{
    width: 100%;
    background-color: #F7F8F9;
    .page{
      width: 1200px;
      margin: auto;
      height: 62px;
      font-size: 12px;
      line-height: 62px;
    }
  }
  .offline-wrap{
    width: 100%;
    background-color: #fff;
    .page{
      width: 1200px;
      margin: auto;
      padding: 89px 0 113px;
      .title{
        font-size: 20px;
        color: #707372;
        line-height: 20px;
        text-align: center;
      }
      .desc{
        font-size: 14px;
        color: #9D9D9D;
        line-height: 14px;
        text-align: center;
        margin-top: 34px;
      }
      .map-wrap{
        width: 100%;
        display: flex;
        margin-top: 60px;
        .left{
          flex: 3;
          .mapbox{
            width: 100%;
            height: 500px;
          }
        }
        .right{
          flex: 1;
          border: 1px solid #d5d7d9;
          .address-item{
            font-size: 14px;
            padding: 20px 10px 20px 15px;
            border-bottom: 1px solid #d5d7d9;
            cursor: pointer;
            &.active{
              background-color: #eeeeee;
            }
            .name{
              color: #707473;
              display: flex;
              font-weight: 600;
              align-items: center;
              >img{
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }
            }
            .address{
              color: #979797;
              font-size: 12px;
              margin-top: 8px;
              display: flex;
              align-items: center;
              .iconfont{
                margin-right: 5px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
</style>